import '@mdi/font/metalchek/css/materialdesignicons.css';
import 'roboto-fontface/metalchek/css/roboto/roboto-fontface.css';
import 'vuetify/dist/vuetify.min.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import en from 'vuetify/src/locale/pt';
import pt from 'vuetify/src/locale/pt';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { en, pt },
    current: 'en',
  },
});
