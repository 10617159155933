

























import Vue from 'vue';

import ToastSnackbar from '@/components/ToastSnackbar.vue';
import Home from './pages/Home.vue';

export default Vue.extend({
  name: 'App',
  components: {
    ToastSnackbar,
    Home,
  },
  data: () => ({
    loading: {
      logout: false,
    },
  }),
  computed: {
    currentLayout() {
      return `Layout${this.$route.meta.layout || 'Default'}`;
    },
    isRouterLoaded() {
      return this.$route.name !== null;
    },
  },
});
