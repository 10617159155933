import Vue from 'vue';
import App from './App.vue';
import VueTheMask from 'vue-the-mask';

// plugins
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueTheMask as any);
new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
