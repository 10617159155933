import axios from 'axios';
import qs from 'querystring';

interface QueryObjectLote {
  Lote: string;
}

// backend base URL
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// send authentication cookie
// axios.defaults.withCredentials = true;
// query string serializer
axios.defaults.paramsSerializer = (params) => qs.stringify(params);

export default {
  buscaDocumento: async (query?: QueryObjectLote): Promise<any> => {
    const res = await axios.get<any>(
      'http://metalcheck.helpb1.dc.nuvem.online:8092/api/GerarPDF',
      {
        params: query || null,
        responseType: 'blob',
      },
    );
    return res.data;
  },
};
