











































import Vue from 'vue';
import Requester from '../requester';

export default Vue.extend({
  name: 'Home',
  data() {
    return {
      loading: false,
      codigoLote: null,
      tokens: {
        '#': {pattern: /\d/},
        'L': {pattern: /[lL]/, transform: (v: string) => v.toLocaleUpperCase()},
        'T': {pattern: /[tT]/, transform: (v: string) => v.toLocaleUpperCase()},
        'X': {pattern: /[0-9a-zA-Z]/},
        'S': {pattern: /[a-zA-Z]/},
        'A': {pattern: /[a-zA-Z]/, transform: (v: string) => v.toLocaleUpperCase()},
        'a': {pattern: /[a-zA-Z]/, transform: (v: string) => v.toLocaleLowerCase()},
        '!': {escape: true}
      }
    }
  },
  methods: {
    async clickBtn() {
      if (!this.codigoLote) {
        this.$root.$emit('showToast',{
          text: 'O código do lote é obrigatório',
          // color: '#4ACE19'
          color: '#E41C3C'
        });
      }
      this.loading = true;
      try {
        const responseData = await Requester.buscaDocumento({
          Lote: this.codigoLote
        });
        const url = window.URL.createObjectURL(new Blob([responseData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.codigoLote}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        if (err.response.status === 400) {
          this.$root.$emit('showToast',{
            text: `Lote "${this.codigoLote}" não encontrado.`,
            color: '#E41C3C'
          });
        } else {
          console.log(err);
          this.$root.$emit('showToast',{
            text: 'Ocorreu um erro desconhecido.',
            color: '#E41C3C'
          });
        }
      } finally {
        this.loading = false;
      }
    }
  },
});
